<template>
  <div style="min-height: 500px">
    <div
      v-if="
        (this.payment.Status == 2 &&
          (this.payment.PaymentMethod == 'Cheque' ||
            this.payment.PaymentMethod == 'Transfer' ||
            this.payment.PaymentMethod == 'Giro')) ||
        (this.payment.Status == 6 &&
          this.payment.PaymentMethod != 'Cheque' &&
          this.payment.PaymentMethod != 'Transfer' &&
          this.payment.PaymentMethod != 'Write-off' &&
          this.payment.PaymentMethod != 'Giro')
      "
    >
      <div class="mb-3">
        <div class="vx-col w-full">
          <label class="vs-input--label">Reversal Date</label>
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            :key="dateKey"
            name="Invoice Date"
            v-model="reversalDate"
            placeholder="Date Clearing"
            :disabled-dates="disabledDates"
          ></datepicker>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <label class="vs-input--label">Note</label>
          <vs-textarea class="w-full" label="Note" v-model="note" />
        </div>
      </div>
      <div class="mb-5">
        <div class="vx-col w-full">
          <br />
          <vs-button
            v-if="$store.getters['user/hasPermissions']('revers')"
            color="red"
            class="mb-2"
            v-on:click="handleReversal"
            >Reversal Payment</vs-button
          >
        </div>
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      class=""
    >
      <template slot="thead">
        <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
          <vs-td>
            {{ tr.InvoiceCode }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.InvoicePaymentAmount) }}
          </vs-td>
          <vs-td>
            {{ tr.Note }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import { getPaymentLine } from "@/services/api/payment";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  props: {
    selected: Object,
    payment: Object,
  },
  components: {
    Datepicker,
  },
  watch: {
    payment() {
      this.reloadData(this.params);
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
        status: 0,
      },
      header: [
        {
          text: "Invoice",
          value: "invoice_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "invoice_payment_amount",
        },
        {
          text: "Note",
          value: "note",
        },
      ],
      uploadReady: true,
      note: "",
      responseData: {},
      selectedData: {},
      disabledDates: {
        from: new Date().setHours(0, 0, 0, 0),
      },
      reversalDate: new Date(),
      dateKey: "DatePayment",
    };
  },
  mounted() {
    this.reversalDate = new Date();
  },
  methods: {
    reloadData(params) {
      this.$vs.loading();
      this.params = params;
      this.params.payment_id = this.payment.ID;
      console.log("<<<<", this.payment.Status);
      const dataTable = getPaymentLine(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
          this.disabledDates.to = moment.utc(this.payment.DatePayment).toDate();
          console.log(this.disabledDates, "disabledDates");
          this.dateKey = "DatePayment" + this.responseData.payment.DatePayment;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
    },
    handleReversal() {
      if (this.note == "") {
        this.$vs.notify({
          title: "Error",
          text: "Note required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      const params = {
        id_payment: this.payment.ID,
        data_reversal: moment(this.reversalDate).format("YYYY-MM-DD"),
        note: this.note,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/payment/reversal", params)
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
