var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.payment),function(tr,indextr){return _c('vs-tr',{key:indextr,class:[_vm.selectedData.ID == tr.ID ? 'colored' : '', '']},[_c('vs-td',[(_vm.selectedData.ID != tr.ID)?[(_vm.$store.getters['user/hasPermissions']('view'))?_c('vx-tooltip',{attrs:{"text":"View"}},[_c('vs-button',{attrs:{"color":"success","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.view(tr)}}})],1):_c('p',[_vm._v("No Action")])]:_vm._e()],2),_c('vs-td',[_vm._v(" "+_vm._s(tr.CustomerCode)+" "+_vm._s(tr.CustomerName)+" ")]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.Code)+" "),_c('br'),_vm._v(" Reference Code : "+_vm._s(tr.ReferenceCode)+" "),_c('br'),_vm._v(" Payment Type : "+_vm._s(_vm.typePayment[tr.Type])+" "),_c('br'),_vm._v(" "+_vm._s(tr.PaymentMethod)+" - "+_vm._s(tr.PaymentName)+" "),_c('br'),(_vm.nonCashPayment.includes(tr.PaymentMethod))?_c('span',[_vm._v("Bank : "+_vm._s(tr.BankName)+" "),_c('br'),_vm._v(" "+_vm._s(tr.BankAccountName)+" - "+_vm._s(tr.BankAccountNumber))]):_vm._e()]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.Amount))+" ")]),_c('vs-td',[_vm._v(" Created Date : "+_vm._s(_vm.dateFormat(tr.CreatedAt))+" "),_c('br'),_vm._v(" Payment Date : "+_vm._s(_vm.dateFormat(tr.DatePayment))+" "),_c('br'),_vm._v(" Posting Date : "+_vm._s(_vm.dateFormat(tr.PostingDate))+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),(_vm.isCreate)?[_c('create',{on:{"closeDetail":_vm.closeDetail}})]:(_vm.isView && _vm.selectedData.ID != 0)?[_c('create',{attrs:{"viewOnly":true,"selected":_vm.selectedData},on:{"closeDetail":_vm.closeDetail}})]:[_c('create',{attrs:{"selected":_vm.selectedData},on:{"closeDetail":_vm.closeDetail}})]],2)]):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"}),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"})])
}]

export { render, staticRenderFns }